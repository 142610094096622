
.ref-res-drawer{
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill,minmax(250px,1fr));
}

.form_input_div_country {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  :global .ant-form-item-required {
    margin-left: -7px !important;
    z-index: 25;
  }
  // :global .ant-select-arrow {
  //   margin-top: 3px;
  // }
  :global .password {
    margin-left: 10px;
  }
}

.form_checkbox_div {
  display: flex;
  flex-direction: column;
}

.form_item_country {
  .select {
    width: 100% !important;
    margin-top: -14px !important;
    margin-left: -7px !important;
    padding: 0px !important;
  }
  width: 250px !important;
  margin: 0% auto;
}

.checkbox-form_midele {
  margin-left: -0px !important;
  // width: 100px;
}

.save_button_div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.save_button {
  width: 200px;
}
