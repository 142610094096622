@import '../../../public/assets/style/variables.scss';



.profile-form-button{
      
    width: 100%;
   
}

.close_error{
    height: 0px;
    overflow: hidden;
    transition: 500ms;
    display: flex;
    justify-content: center;
}

.open_error{
    height: 40px;
    transition:  500ms;
    overflow: hidden;
    margin: 0% auto;
    display: flex;
    justify-content: center;

}
   
.error_message{
    color: $error_color;
    font-size: 14px; 
    
}
