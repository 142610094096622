@import "../../../public/assets/style/variables.scss";

.manage-categories-page {
  position: relative;
  padding-bottom: 30px;
}

.xtream-palylists-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;

  &__item {
    width: 390px;
  }

  &__DndContext {
    width: 390px;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
  }
}

.manage-categories-form {
  &__items {
    width: 250px;
  }
}

.manage-categories-page-title {
  @include dashboard_font_text;
  font-size: 25px;
  margin-bottom: 20px !important;
}

.pro-widget-warning-text {
  background-color: rgba(255, 229, 100, 0.3);
  border-left-color: #ffe564;
  border-left-width: 9px;
  border-left-style: solid;
  padding: 20px 45px 20px 26px;
  margin-bottom: 30px;
}


.pro-widget-tab-layer {
  background-color: rgba(229, 229, 229, 0.5);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  z-index: 99;
}