.btn {
    float: right;
    margin-bottom: 20px;
}

.btn-container {
    width: max-content;
    margin: 0 auto;
}

.page-title-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.permissions-list {
    display: flex;
    justify-content: space-between;
    gap: 30px;
}

.checkboxes-list {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    margin-bottom: 30px;

    .checkbox {
        margin-left: 0;
    }
}

@media screen and (max-width:500px) {
    .permissions-list {
        flex-wrap: wrap;
        gap: 10px;
    }
}