.messages_container_active {
  width: 100%;
  padding: 5px 20px;
  overflow-y: scroll;
  word-break: break-all !important;
  height: 483px;
}

.messages_container {
  display: none;
  word-break: break-all;
  word-break: break-all;
}

.user_message {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  max-width: 80%;
  overflow-wrap: break-word;
  margin:10px 0;
  p {
    word-break: break-all;
    padding: 10px 16px;
    border-radius: 8px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    background: #f0f2f5;
    color: #a8adbd;
  }
}

.admin_message {
  list-style: none;
  margin: 10px auto;
  display: flex;
  justify-content: flex-end;
  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.5px;
    color: #ffffff;
    background: #073e4a;
    padding: 10px 16px;
    border-radius: 8px;
  }
}
