.leng_list_title{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.save_button_div{
    width: 100%;
    display: flex;
    justify-content: center;
}

.save_button{
  width: 150px;
}



