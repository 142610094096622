.user_info {
  background: #4ce147;
  border-radius: 8px 8px 0px 0px;
  height: 40px;
  padding-left: 15px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 124%;
  display: flex;
  align-items: center;
  letter-spacing: 0.02em;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 7px;
  line-height: 1;
  margin-bottom: 0;
}

.user_info_none {
  display: none;
}

.message-chat-back-button{
  cursor: pointer;
}
