.chat_items_container {
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .chat_list_mac {
    height: 22px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #262626;
    margin: 0px;
    padding: 0px;
  }
}

.list{
  height: calc(100% - 35px);
  overflow: auto;
  padding:0 10px;
}

.chat_items_container_active {
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background-color: #eceffd;
  padding: -10px;
  border-radius: 5px;

  .chat_list_mac {
    height: 22px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color:#7e7a7a;
    margin: 0px;
    padding: 0px;
  }
}

.chat_list_item {
  display: flex;
  flex-direction: column;
  width: 80%;
  border-radius: 8px;
  justify-content: center;
  margin: 0px;
}

.chat_last_message {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  color:#7e7a7a;
  padding: 0px;
  margin: 0px;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.new_chat_indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #52c41a;
  margin-left: 5px;
}

.unanswered_chat_indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #a8adbd;
  margin-left: 5px;
}


