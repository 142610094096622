@import "../index.module.scss";

.playlist_save_div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.playlist_save {
  width: 150px;
  margin: 0% auto;
}

.protected-playlist-checkbox {
  margin-bottom: 24px !important;
}
