@import '../../../public/assets/style/variables.scss';
.ReferralLinks_title-text{
    @include dashboard_font_text;
    font-size:25px;
  }


  .ref-link {
    white-space: nowrap;
    width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
  }