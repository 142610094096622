.geo-restriction-setion {
  position: relative;

  &_title {
    font-size: 28px;
    font-weight: 600;
  }

  &_btn-container {
    margin-top: 40px;
    margin-left: 165px;
  }
}

@media screen and (max-width: 840px) {
  .geo-restriction-setion_transfer {
    .ant-transfer-list {
      width: 145px !important;
    }
  }

  .geo-restriction-setion_btn-container{
    margin-left: 140px !important;
  }
}
