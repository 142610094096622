.select {
    margin-top: 20px;
    margin-right: 15px;
    width: 70px !important;

    .iso-code {
        font-size: 8px !important;
    }

    img {
        width: 25px !important;
    }
}

.lanugage-icon {
    cursor: no-drop;
    padding-top: 15px;

    svg {
        width: 30px;
        height: 30px;
        color: grey;
    }
}

.dropdown {
    width: max-content !important;

    img {
        margin-right: 10px;
        width: 25px !important;
    }

    .iso-code {
        font-size: 12px !important;
    }
}

.dropdown {
    overflow-y: scroll;
    width: 80px !important;
}

.dropdown::-webkit-scrollbar {
    width: 4px;
}

.dropdown::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.dropdown::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    border-radius: 3px;
    //outline: 1px solid slategrey;
}
