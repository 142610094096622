.checkbox_div {
  display: flex;
}

.save_button_div {
  width: 100%;
  display: flex;
  justify-content: center;
}

.save_button {
  width: 150px;
}

.checkbox_yearly {
  margin-bottom: -5px !important;
}

.package_add {
  position: fixed;
  right: 20px;
}

.form_item_select {
  .select {
    width: 100% !important;
    padding: 0px !important;
  }
  width: 100% !important;
  margin: 0% auto;
}

.app_uload_form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.uload_img {
  width: 150px;
  border-radius: 15px;
}

.uload_button {
  margin-top: 15px;
}
