@import '../index.module.scss';

.save_button_div{
    width: 100%;
    display: flex;
    justify-content: center;
}

.save_button{
    width: 150px;
    
}