.chatBar_container {
  background-color: #fff;
  height: 100%;
  width: 29%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
}

@media screen and (max-width:600px) {
  .chatBar_container{
    width: 82vw;
    margin-top: 20px;
  }
}