@import "../../../public/assets/style/variables.scss";

.support_list_table {
  position: relative;
}

.support_list_title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 80px;
  align-items: center;
}

.support_title-text {
  @include dashboard_font_text;
  font-size: 25px;
  margin-bottom: 0px !important;
}

// .ref-link {
//   white-space: nowrap;
//   width: 250px;
//   overflow: hidden;
//   text-overflow: ellipsis;
// }

.message_container {
  display: flex;
  align-items: center;
  // justify-content: center;
  padding: 0px !important;
}

.new_message_indicator {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #52c41a;
  margin-right: 5px;
}

.viewed_message_indicator {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #1890ff;
  margin-right: 5px;
}

.answered_message_indicator {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #d9d9d9;
  margin-right: 5px;
}

.new_message_status {
  display: flex;
  padding: 0px;
  margin: 0px !important;
  font-size: 14px;
  font-weight: 600;
  color: #d9d9d9;
  color: rgba(0, 0, 0, 0.85);
  white-space: nowrap;
  // width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.answered_message_status {
  display: flex;
  padding: 0px;
  margin: 0px !important;
  font-size: 14px;
  color: #d9d9d9;
  white-space: nowrap;
  // width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.viewed_message_status {
  padding: 0px;
  margin: 0px !important;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.85);
  white-space: nowrap;
  // width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}

// .message_email_div {
// }

.support_row td {
  height: 45px !important;
  padding: 5px 16px !important;
  overflow: hidden;
}

:global .ant-popover-inner-content {
  max-width: 700px;
}
