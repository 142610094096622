.upload_img.ant-image-img {
  width: 150px;
  border-radius: 15px;
}
.app_upload_form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app_info_img {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  // width: 400px;
}

.payment-settings-tab-form {
  // width: 350px;
}

.branding__text-form {
  width: 350px;

}

.google-reCaptcha-title,.xtream-ui-title{
  font-size: 28px;
  font-weight: 600;
}

.xtream-ui-ids-title{
  font-size:16px;
  font-weight: 600;
}

.payment-types-container{
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.payment-type{
  width: 300px;
}

@media screen and (max-width: 640px) {
  .payment-settings-tab-form {
    // width: 48vw;
  }
   .app_info_img {
    flex-direction: column !important;
    // width: 284px;
    align-items: center;
    margin: auto;
  }
  .upload_img.ant-image-img {
    width: 20vw;
  }

  .branding__text-form {
    width: 250px;
    margin: auto;
  }
}

@media screen and (max-width: 539px) {
  .web-translation-tabs-component {
    width: 70vw;
  }
}
