@import '../../../public/assets/style/variables.scss';

.withdraw_title-text{
  @include dashboard_font_text;
  font-size:25px;
 

}

:global .ant-table-filter-trigger.active {
  --ant-primary-color: rgb(47, 175, 218)!important;
}

:global .ant-table-column-sorter-inner {
  --ant-primary-color: rgb(47, 175, 218)!important;
}