@import '../index.module.scss';
.app_info{
    width: 250px;
    padding: 15px, 20px;
    display: flex;
    flex-direction: column;
    
}


.app_info_form{
width: 100%;
display: flex;
justify-content: space-between;
}

.ant_input_price{
  width: 100%;


}
.form_item_price{
  width: 46%;
}

.currencies_div{
 width: 100%;
 display: flex;
 justify-content: space-between;
 
}

.form_item_currencies {
  width: 100%!important;
    // margin: 0% auto;
  :global .ant-form-item-label{
    label{margin-top: 0px !important;}
    }

    :global .ant-form-item-control-input{
         margin-top: 0px;
    }
    .select {
      width: 100%!important;
      padding: 0px !important;
      margin-top: -14px !important;
    }
    
  }
  

.label_div{
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 5px;
}
.app_info_inputs{
width: 100%;
// padding: 5px 20px;
// box-shadow: 4px 4px 12px #0000001C;
}

.aplication_button{
  width: 100%;
}