.search-elements-container {
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 10px;

    .search-elements {
        display: flex;
        gap: 10px;
    }
}

.table-top-content {
    width: 100%;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
}

.name-input {
    display: flex;
    align-items: center;
    gap: 10px;

    .name {
        min-width: 100px;
    }
}

.next-btn {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
}

.table-bottom-btns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.toggle-next-btn {
    display: flex;
    gap: 10px;
}

.button-container {
    width: max-content;
    margin: auto;
}


.row {
    cursor: pointer;
}

.selected-row {
    background: #ffc1077d;

    &:hover {
        td {
            background: none !important;
        }
    }
}