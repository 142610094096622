
.checkbox_div{
    display: flex;
    
   
}

.checkboxes-container{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
}

.save_button_div{
    width: 100%;
    display: flex;
    justify-content: center;
}

.save_button{
    width: 150px;
}


.checkbox_yearly{
    margin-bottom: -5px !important;
}

.package_add{
    position: fixed;
    right: 20px;
}

.form_item_select{
    .select {
        width: 100%!important;
        padding: 0px !important
      }
      width: 100%!important;
      margin: 0% auto;
}